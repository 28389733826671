import axios, {AxiosResponse} from "axios";
import { IDay } from "../models/day";

//axios.defaults.baseURL = 'http://localhost:5000/api';
axios.defaults.baseURL = 'https://aulaga-api.restaurantepedagogicoaulaga.es/api';

const responseBody = (response: AxiosResponse) => response.data;

const request = {
    get: (url: string) => axios.get(url).then(responseBody)
};

const Days = {
    availableDaysList: () : Promise<IDay[]> => request.get('/day/available'),
};

export default Days;
