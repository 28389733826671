import React, {useContext, useEffect, useState} from "react";
import RiveAnimationComponent from "../components/RiveAnimationComponent";
import {ErrorMessage, Field, Form, Formik} from "formik";
import day from "../api/day";
import {IDay} from "../models/day";
import {IReservation} from "../models/reservation";
import reservation from "../api/reservation";
import LoadingComponent from "../components/LoadingComponent";
import ReCAPTCHA from "react-google-recaptcha";
import {OtherContext} from "../context/OtherContext";
import InformationModalComponent from "../components/InformationModalComponent";
import {Transition} from "@headlessui/react";
import {reservationValidation} from "../validation/reservationValidation";
import TextAreaComponent from "../components/TextAreaComponent";
import InformationModalComponentWithoutReload from "../components/InformationModalComponentWithoutReload";

const IndexScreen = () => {

    const labelStyles = "font-bold mb-2 text-sm md:text-base";
    const inputStyles = "border-2 border-indigo-500 rounded-lg p-1 mb-2";

    const [checked, setCheck] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [bodyModal, setBodyModal] = useState({
        title: '',
        message: '',
        buttonText: ''
    });

    const [showNewModal, setShowNewModal] = useState(false);
    const [bodyNewModal, setBodyNewModal] = useState({
        title: '',
        message: '',
        buttonText: ''
    });

    const initialValueReservation: IReservation = {
        name: '',
        surname: '',
        telefone: '',
        email: '',
        allergens: '',
        numberOfPeople: 0,
        dayId: 0,
        isTermsAndPrivacyAccepted: false,
        isTheyWantReceiveOffers: false
    };

    function mapping(array: IDay[]): any[] {
        return array.map(value => {
            return value
        })
    }

    const [isVisible, setVisibility] = useState(false);
    const [daysAvailable, setDaysAvailable] = useState<IDay[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [validRecaptcha, setValidRecaptcha] = useState(false);
    const [allergens, setAllergens] = useState('');
    const recaptcha: React.RefObject<ReCAPTCHA> = React.createRef<ReCAPTCHA>();
    const {others} = useContext(OtherContext);

    useEffect(() => {
        setLoading(true);
        day.availableDaysList()
           .then((response) => {
               setDaysAvailable(mapping(response));
               setLoading(false)
           })
           .catch((error) => {
               setLoading(false);
               console.log(error)
           })
    }, []);

    function onClick() {
        if (isVisible) {
            setVisibility(false)
        } else {
            setVisibility(true)
        }
    }
    
    function onChange() {
        if(recaptcha.current?.getValue()) {
            console.log(recaptcha.current?.getValue());
            setValidRecaptcha(true)
        } else {
            setValidRecaptcha(false)
        }
    }

    function onChangeCheck() {
        if (checked === 0) {
            setCheck(1)
        } else if (checked === 1) {
            setCheck(2)
        } else {
            setCheck(1)
        }
    }

    function styles(url: any) {
        return {
            backgroundImage: `url(${url})`,
        }
    }

    function checkError(error: string) {
        if (error.includes("500")) {
            setBodyModal({
                title: 'Error de conexión',
                message: 'Si el problema persiste, vuelva a intentarlo más tarde',
                buttonText: 'Recargar'
            });
            setShowModal(true)
        } else if (error.includes("400")) {
            setBodyModal({
                title: 'La reserva esta llena o no cabeis en el aforo actual',
                message: 'La reserva acaba de llenarse o no cabeis en el aforo actual, en cualquier caso, recargar la página.',
                buttonText: 'Recargar'
            });
            setShowModal(true)
        }
    }

    return (
        <div className={"w-full min-h-screen h-auto flex flex-col bg-no-repeat bg-center bg-cover overflow-x-hidden"} style={styles("./img/background.webp")}>
            <div className={"flex flex-row justify-between py-4 px-8"}>
                <div className="block h-4 w-12 lg:h-12 p-2">
                </div>
                <div className={"flex flex-row gap-2"}>
                    <button className={"py-2 px-2 md:px-4 border border-white bg-none rounded-lg text-white text-sm md:text-base transition duration-300 ease-in-out hover:bg-white hover:text-black"} onClick={onClick}>
                        Reservar
                    </button>
                    <a href={others.urlForm}>
                        <button className={(others.urlForm != null) ? "px-2 md:px-4 py-2 h-full border border-white bg-none rounded-lg text-white text-sm md:text-base transition duration-300 ease-in-out hover:bg-white hover:text-black" : "hidden"}>
                            Formulario de satisfacción
                        </button>
                    </a>

                </div>
            </div>
            <div className="w-full h-auto flex flex-row lg:flex-column flex-wrap">
                <div className={"lg:w-2/6"}>

                </div>
                <div className={(isVisible) ? "hidden w-full lg:block lg:w-2/6" : "block w-full h-screen lg:w-2/6"}>
                    <RiveAnimationComponent />
                </div>
                <div className={"w-full h-auto lg:w-2/6 flex flex-col justify-center"}>
                    <div className={'block h-auto flex flex-col justify-center px-2 lg:px-0'}>
                        <div className={(isVisible) ? "flex h-auto flex-col mb-4 lg:mr-4 drop-shadow-2xl bg-white rounded-lg transition-transform duration-500 easy-all -translate-x-0"
                            : "bg-white mb-4 rounded-lg hidden lg:block lg:transition-transform lg:duration-500 lg:easy-all lg:translate-x-full"}>
                            <div className={(isLoading) ? 'block flex flex-row justify-center items-center' : 'hidden'}>
                                <div className={"w-40 h-40"}>
                                    <LoadingComponent />
                                </div>
                            </div>
                            <Formik enableReinitialize={true} validationSchema={reservationValidation} initialValues={initialValueReservation} onSubmit={async values => {
                                if (recaptcha.current?.getValue()){
                                    setLoading(true);
                                    await reservation.create(values).then(() => {
                                        setBodyModal({
                                            title: 'Reserva guardada',
                                            message: 'La reserva ha sido guardada, en breve recibira un mensaje en su correo electrónico, muchas gracias.',
                                            buttonText: 'Vale'
                                        });
                                        setShowModal(true);
                                    }).catch((error) => checkError(error.toString())).finally(() => setLoading(false))
                                }
                            }}>{(formikProps) => (
                                <Form className={(isLoading) ? "hidden" :"block h-auto mb-2 px-4 lg:px-12 py-2"}>
                                    <h3 className={"text-lg md:text-2xl text-indigo-500"}>Reservar</h3>
                                    <div className="flex flex-col py-2">
                                        <div className={"flex flex-col"}>
                                            <div className={"flex flex-row"}>
                                                <label htmlFor={"dayId"} className={labelStyles}>Días disponibles <span className={"text-red-500"}>*</span></label>
                                                <p className={(daysAvailable.length <= 0) ? "block text-blue-500 text-sm md:text-base ml-2" : "hidden"}>Cerrado temporalmente</p>
                                                <ErrorMessage name={"dayId"}>{message =>
                                                    <p className={"text-red-500 text-sm md:text-base ml-2"}>{message}</p>
                                                }</ErrorMessage>
                                            </div>

                                            <Field as={"select"} name={"dayId"} className={inputStyles} disabled={(daysAvailable.length === 0)}>
                                                <option value={0}>
                                                    Selecciona un día
                                                </option>
                                                {
                                                    daysAvailable.map((object) => {
                                                        return (
                                                            <option key={object.id} value={object.id}>
                                                                {object.date + ' - Aforo disp. ' + (object.maxPeople - object.people) + ' - ' + object.openHour + ' / ' + object.closeHour}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </Field>
                                        </div>
                                        <div className={"flex flex-col"}>
                                            <div className={"flex flex-row"}>
                                                <label htmlFor={"name"} className={labelStyles}>Nombre <span className={"text-red-500"}>*</span></label>
                                                <ErrorMessage name={"name"}>{message =>
                                                    <p className={"text-red-500 text-sm md:text-base ml-2"}>{message}</p>
                                                }</ErrorMessage>
                                            </div>

                                            <Field type={"text"} name={"name"} className={inputStyles} disabled={(daysAvailable.length === 0)} />
                                        </div>
                                        <div className={"flex flex-col"}>
                                            <div className={"flex flex-row"}>
                                                <label htmlFor={"surname"} className={labelStyles}>Apellidos <span className={"text-red-500"}>*</span></label>
                                                <ErrorMessage name={"surname"}>{message =>
                                                    <p className={"text-red-500 text-sm md:text-base ml-2"}>{message}</p>
                                                }</ErrorMessage>
                                            </div>

                                            <Field type={"text"} name={"surname"} className={inputStyles} disabled={(daysAvailable.length === 0)} />
                                        </div>
                                        <div className={"flex flex-col"}>
                                            <div className={"flex flex-row"}>
                                                <label htmlFor={"telefone"} className={labelStyles}>Teléfono de contacto <span className={"text-red-500"}>*</span></label>
                                                <ErrorMessage name={"telefone"}>{message =>
                                                    <p className={"text-red-500 text-sm md:text-base ml-2"}>{message}</p>
                                                }</ErrorMessage>
                                            </div>

                                            <Field type={"text"} name={"telefone"} className={inputStyles} disabled={(daysAvailable.length === 0)} />
                                        </div>
                                        <div className={"flex flex-col"}>
                                            <div className={"flex flex-row"}>
                                                <label htmlFor={"email"} className={labelStyles}>Correo electrónico <span className={"text-red-500"}>*</span></label>
                                                <ErrorMessage name={"email"}>{message =>
                                                    <p className={"text-red-500 text-sm md:text-base ml-2"}>{message}</p>
                                                }</ErrorMessage>
                                            </div>

                                            <Field type={"email"} name={"email"} className={inputStyles} disabled={(daysAvailable.length === 0)} />
                                        </div>
                                        <div className={"flex flex-col"}>
                                            <div className={"flex flex-row"}>
                                                <label htmlFor={"numberOfPeople"} className={labelStyles}>Número de personas que asistirán <span className={"text-red-500"}>*</span></label>
                                                <ErrorMessage name={"numberOfPeople"}>{message =>
                                                    <p className={"text-red-500 text-sm md:text-base ml-2"}>{message}</p>
                                                }</ErrorMessage>
                                            </div>

                                            <Field type={"number"} name={"numberOfPeople"} min={1} className={inputStyles} disabled={(daysAvailable.length === 0)} />
                                        </div>

                                        <div className={"flex flex-col"}>
                                            <div className={"flex flex-row"}>
                                                <label htmlFor={"allergens"} className={labelStyles}>Alergenos que padezcan usted o sus acompañantes</label>
                                            </div>
                                        </div>

                                        <TextAreaComponent field={"allergens"} inputStyle={inputStyles} setDescription={setAllergens} />

                                        <div>
                                            <label htmlFor={"isTermsAndPrivacyAccepted"} className={(checked === 1 || checked === 0) ? "text-black-500 text-sm md:text-base" : "text-red-500 text-sm md:text-base"}>
                                                <Field type={"checkbox"} name={"isTermsAndPrivacyAccepted"} />
                                                &nbsp;&nbsp;<span className={"text-red-500"}>*</span>Acepto las <span className={"text-primary cursor-pointer"} onClick={() => {
                                                setBodyNewModal({
                                                        title: 'Políticas de Privacidad',
                                                        message: others.privacyAndPolicies,
                                                        buttonText: 'Cerrar'
                                                    });
                                                    setShowNewModal(true);
                                                }
                                                }>Políticas de Privacidad</span> y Acepto los <span className={"text-primary cursor-pointer"} onClick={() => {
                                                    setBodyNewModal({
                                                        title: 'Términos y Condiciones',
                                                        message: others.termsAndConditions,
                                                        buttonText: 'Cerrar'
                                                    });
                                                    setShowNewModal(true);
                                                }
                                                }>Términos y Condiciones</span>
                                                <ErrorMessage name={"isTermsAndPrivacyAccepted"}>{message =>
                                                    <p className={"text-red-500 font-normal"}>{message}</p>
                                                }</ErrorMessage>
                                            </label>
                                        </div>
                                        <div>
                                            <label htmlFor={"isTheyWantReceiveOffers"} className={(checked === 1 || checked === 0) ? "text-black-500 text-sm md:text-base" : "text-red-500 text-sm md:text-base"}>
                                                <Field type={"checkbox"} name={"isTheyWantReceiveOffers"} />
                                                &nbsp;&nbsp; Acepto recibir notificaciones de ofertas del restaurante
                                            </label>
                                        </div>

                                        <div className={"flex flex-col py-2"}>
                                            <ReCAPTCHA
                                                className={"flex flex-row justify-center"}
                                                ref={recaptcha}
                                                sitekey="6LehpeYlAAAAADve2BVbRquHUJN5XY6Ia4Zcl7SC"
                                                onChange={onChange} />
                                        </div>

                                        <input type="submit" value={"Reservar"} className={"bg-indigo-500 text-white p-2 rounded-lg mt-2 cursor-pointer"} disabled={(daysAvailable.length === 0)}/>
                                    </div>
                                </Form>
                            )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>

            <Transition
                appear={true}
                show={showModal}
                enter={"transition-opacity duration-300"}
                enterFrom={"opacity-0"}
                enterTo={"opacity-100"}
                leave={"transition-opacity duration-200"}
                leaveFrom={"opacity-100"}
                leaveTo={"opacity-0"}>
                <InformationModalComponent title={bodyModal.title} message={bodyModal.message} buttonText={bodyModal.buttonText} onChange={setShowModal} />
            </Transition>
            <Transition
                appear={true}
                show={showNewModal}
                enter={"transition-opacity duration-300"}
                enterFrom={"opacity-0"}
                enterTo={"opacity-100"}
                leave={"transition-opacity duration-200"}
                leaveFrom={"opacity-100"}
                leaveTo={"opacity-0"}>
                <InformationModalComponentWithoutReload title={bodyNewModal.title} message={bodyNewModal.message} buttonText={bodyNewModal.buttonText} onChange={setShowNewModal} />
            </Transition>
        </div>
    );
};

export default IndexScreen;
