import {IOtherDetail} from "../models/other";
import {createContext} from "react";
import {IOtherType} from "../types/otherType";

interface IOtherContext {
    others: IOtherDetail,
    dispatch: any
}

const defaultState: IOtherContext = {
    others: {
        teamDescription: '',
        phoneNumber: '',
        urlForm: '',
        termsAndConditions: '',
        privacyAndPolicies: ''
    },
    dispatch: null
};

export const OtherContext = createContext<IOtherContext>(defaultState);
