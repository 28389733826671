import React from "react";
import {Interweave} from "interweave";

interface IInformationModalComponent {
    title: string,
    message: string,
    buttonText: string,
    onChange: any
}

const InformationModalComponentWithoutReload = (props: IInformationModalComponent) => {
    return (
        <>
            <div className={"h-screen w-screen z-10 px-2 flex items-center justify-center fixed top-0 left-0 bg-black bg-opacity-50 "}>
                <div className={"w-full h-4/5 md:w-3/4 lg:w-1/2 overflow-y-scroll z-20 flex flex-col justify-between bg-white opacity-100 rounded-lg"}>
                    <div className={"p-4"}>
                    <p className={"py-2 text-xl text-red-500"}>{props.title}</p>
                        <Interweave content={"<div class='text-sm lg:text-lg text-justify py-2'>" + props.message + "</div>"}/>
                        <button className={"py-2 rounded-lg text-blue-500"} onClick={() => props.onChange(false)}>{props.buttonText}</button>
                    </div>
                </div>
            </div>
    </>
);
};

export default InformationModalComponentWithoutReload;
