import * as Yup from 'yup'
const phoneRegExp = /^\+?(6\d{2}|7[1-9]\d{1})\d{6}$/;

export const reservationValidation = Yup.object({
    dayId: Yup.number().min(1, 'Es necesario especificar el día'),
    name: Yup.string().required('Este campo es obligatorio'),
    surname: Yup.string().required('Este campo es obligatorio'),
    telefone: Yup.string().required('Este campo es obligatorio')
        .matches(phoneRegExp, 'Este número de telefono no es valido')
        .max(9, 'Este número de telefono no es valido'),
    email: Yup.string().required('Este campo es obligatorio'),
    numberOfPeople: Yup.number().min(1, 'Debe ser mínimo 1'),
    isTermsAndPrivacyAccepted: Yup.bool().oneOf([true], 'Es obligatorio aceptar nuestros terminos y condiciones y nuestras políticas de privacidad'),
});
