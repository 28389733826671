import axios, {AxiosResponse} from "axios";
import {IOtherDetail, ITeamImage} from "../models/other";

//axios.defaults.baseURL = 'http://localhost:5000/api';
axios.defaults.baseURL = 'https://aulaga-api.restaurantepedagogicoaulaga.es/api';

const responseBody = (response: AxiosResponse) => response.data;

const request = {
    get: (url: string) => axios.get(url).then(responseBody),
};

const Other = {
    get: (): Promise<IOtherDetail> => request.get('/other'),
    getImage: (): Promise<ITeamImage> => request.get('/other/team')
};

export default Other;
