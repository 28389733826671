import React, {useContext} from "react";
import {OtherContext} from "../context/OtherContext";

const Footer = () => {
    const {others} = useContext(OtherContext);
    let year = new Date().getFullYear();

    return (
      <div className={"w-full flex flex-col bg-zinc-200 items-center"}>
          <div className={"w-full flex flex-col lg:flex-row px-16 py-8 justify-between"}>
              <div className={"flex flex-row"}>
                  <div className={"flex flex-col w-52"}>
                      <div className={"flex flex flex-col mb-4"}>
                          <p className={"text-lg md:text-xl mb-2"}>Ubicación</p>
                          <p className={"text-sm"}>Polígono Residencial de Arinaga, C. Alcorac, 50, 35118 Agüimes, Las Palmas</p>
                      </div>
                      <div className={"flex flex flex-col"}>
                          <p className={"text-lg md:text-xl mb-2"}>Teléfono (Whatsapp)</p>
                          <p className={"text-sm"}>{others.phoneNumber}</p>
                      </div>
                  </div>
                  <div className={"flex flex-col ml-4"}>
                      <p className={"text-lg md:text-xl mb-2"}>Redes sociales</p>
                      <div className={"flex flex-row"}>
                          <button className={"w-9 h-9 rounded-full border border-neutral-900 p-2"}>
                              <a href="https://www.instagram.com/cifp_villa_de_aguimes_/">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.518 31.51">
                                      <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M15.757,9.914a8.079,8.079,0,1,0,8.079,8.079A8.066,8.066,0,0,0,15.757,9.914Zm0,13.331a5.252,5.252,0,1,1,5.252-5.252,5.262,5.262,0,0,1-5.252,5.252ZM26.051,9.584A1.884,1.884,0,1,1,24.166,7.7,1.88,1.88,0,0,1,26.051,9.584ZM31.4,11.5a9.325,9.325,0,0,0-2.545-6.6,9.387,9.387,0,0,0-6.6-2.545c-2.6-.148-10.4-.148-13,0a9.373,9.373,0,0,0-6.6,2.538,9.356,9.356,0,0,0-2.545,6.6c-.148,2.6-.148,10.4,0,13a9.325,9.325,0,0,0,2.545,6.6,9.4,9.4,0,0,0,6.6,2.545c2.6.148,10.4.148,13,0a9.325,9.325,0,0,0,6.6-2.545,9.387,9.387,0,0,0,2.545-6.6c.148-2.6.148-10.392,0-12.994ZM28.041,27.281a5.318,5.318,0,0,1-3,3c-2.074.823-7,.633-9.288.633s-7.221.183-9.288-.633a5.318,5.318,0,0,1-3-3c-.823-2.074-.633-7-.633-9.288s-.183-7.221.633-9.288a5.318,5.318,0,0,1,3-3c2.074-.823,7-.633,9.288-.633s7.221-.183,9.288.633a5.318,5.318,0,0,1,3,3c.823,2.074.633,7,.633,9.288S28.863,25.214,28.041,27.281Z" transform="translate(0.005 -2.238)"/>
                                  </svg>
                              </a>
                          </button>
                          <button className={"w-9 h-9 rounded-full border border-neutral-900 p-2 ml-2"}>
                              <a href="https://www.facebook.com/villadeaguimes.cifp">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.875 34.664">
                                      <path id="Icon_awesome-facebook" data-name="Icon awesome-facebook" d="M35.438,18A17.438,17.438,0,1,0,15.275,35.227V23.041h-4.43V18h4.43V14.158c0-4.37,2.6-6.784,6.586-6.784a26.836,26.836,0,0,1,3.9.34V12h-2.2a2.52,2.52,0,0,0-2.841,2.723V18h4.836l-.773,5.041H20.725V35.227A17.444,17.444,0,0,0,35.438,18Z" transform="translate(-0.563 -0.563)"/>
                                  </svg>
                              </a>
                          </button>
                          <button className={"w-9 h-9 rounded-full border border-neutral-900 p-2 ml-2"}>
                              <a href="https://twitter.com/cifpvillaguimes?t=ElU_45cgKYoSANTwDB2-TA&s=09">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                      <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/>
                                  </svg>
                              </a>
                          </button>
                      </div>
                  </div>
              </div>
              <div className={"flex flex-row flex-wrap justify-between items-end"}>
                  <img className={"h-16 md:h-20 mr-2"} src={"./img/villa.webp"} alt="cifpvillaagüimes"/>
                  <img className={"h-16 mr-2 md:mb-2"} src={"./img/gobcan.webp"} alt="gobiernodecanarias"/>
                  <img className={"h-16 md:h-20 mr-2"} src={"./img/fondosoeu.webp"} alt="fondosocialeuropeo"/>
                  <img className={"h-16 md:h-20 mr-2"} src={"./img/demetrio.webp"} alt="alejandrodemetrio"/>
              </div>
          </div>
          <p>Copyright &copy; {year} <b>Restaurante Aulaga</b>. Todos los derechos reservados.</p>
      </div>
    );
};

export default Footer;
