import axios, {AxiosResponse} from "axios";
import {IReservation} from "../models/reservation";

//axios.defaults.baseURL = 'http://localhost:5000/api';
axios.defaults.baseURL = 'https://aulaga-api.restaurantepedagogicoaulaga.es/api';

const responseBody = (response: AxiosResponse) => response.data;

const request = {
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody)
};

const Reservations = {
    get: (id: string) => request.get(`/reservation/${id}`),
    create: (reservation: IReservation) => request.post('/reservation', reservation),
    cancel: (id: string, dayId: string) => request.delete(`/reservation/${id}/${dayId}`)
};

export default Reservations;
