import axios, {AxiosResponse} from "axios";
import {IPlateDetail} from "../models/plate";

//axios.defaults.baseURL = 'http://localhost:5000/api';
axios.defaults.baseURL = 'https://aulaga-api.restaurantepedagogicoaulaga.es/api';

const responseBody = (response: AxiosResponse) => response.data;

const request = {
    get: (url: string) => axios.get(url).then(responseBody)
};

const Plates = {
    getPlate: (id: number) : Promise<IPlateDetail> => request.get(`/plate/${id}`),
};

export default Plates;
