import axios, {AxiosResponse} from "axios";
import {IWeekMenuDetail} from "../models/weekMenu";

//axios.defaults.baseURL = 'http://localhost:5000/api';
axios.defaults.baseURL = 'https://aulaga-api.restaurantepedagogicoaulaga.es/api';

const responseBody = (response: AxiosResponse) => response.data;

const request = {
    get: (url: string) => axios.get(url).then(responseBody)
};

const WeekMenu = {
    currentWeekMenu: () : Promise<IWeekMenuDetail> => request.get('/weekmenu/current')
};

export default WeekMenu;
