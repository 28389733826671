import React, {useEffect, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {useHistory, useParams } from "react-router-dom";
import reservation from "../api/reservation";
import LoadingComponent from "../components/LoadingComponent";
import InformationModalComponent from "../components/InformationModalComponent";
import {Transition} from "@headlessui/react";

interface IRouteParams {
    id: string
    dayId: string
}

const CancelReservationConfirmationScreen = () => {
    const recaptcha: React.RefObject<ReCAPTCHA> = React.createRef<ReCAPTCHA>();
    const [validRecaptcha, setValidRecaptcha] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isExist, setExist] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [bodyModal, setBodyModal] = useState({
        title: '',
        message: '',
        buttonText: ''
    });

    const params = useParams<IRouteParams>();
    const history = useHistory();

    function onChange() {
        if(recaptcha.current?.getValue()) {
            console.log(recaptcha.current?.getValue());
            setValidRecaptcha(true)
        } else {
            setValidRecaptcha(false)
        }
    }

    function styles(url: any) {
        return {
            backgroundImage: `url(${url})`,
        }
    }

    function onSubmit() {
        if (recaptcha?.current?.getValue()) {
            setLoading(true);

            reservation.cancel(params.id, params.dayId)
                .then(() => history.push("/"))
                .catch((error) => checkError(error.toString()))
                .finally(() => setLoading(false))
        }
    }

    function checkError(error: string) {
        if (error.includes("500")) {
            setBodyModal({
                title: 'Error de conexión',
                message: 'Si el problema persiste, vuelva a intentarlo más tarde',
                buttonText: 'Recargar'
            });
            setShowModal(true)
        } else if (error.includes("404")) {
            setExist(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        reservation.get(params.id)
            .then(() => setExist(true))
            .catch((error) => checkError(error.toString()))
            .finally(() => setLoading(false))
    }, []);

    return (
        <div className={"h-screen w-screen flex flex-col justify-center items-center bg-no-repeat bg-center bg-cover px-2"} style={styles("../img/background.webp")}>
            <div className={"flex flex-col lg:mr-4 drop-shadow-2xl bg-white rounded-lg w-full md:w-1/2 lg:w-1/3 py-4 px-8"}>
                <div className={(isLoading) ? 'block flex flex-row justify-center items-center' : 'hidden'}>
                    <div className={"w-40 h-40"}>
                        <LoadingComponent />
                    </div>
                </div>
                <div className={(isLoading || isExist) ? 'hidden' : 'flex flex-col'}>
                    <p className={"py-2"}>La reserva que intenta cancelar ya ha sido borrada o no existe.</p>
                    <button className={"mt-4 bg-indigo-500 rounded-lg py-2 text-white"} onClick={() => history.push("/")}>
                        Hacer una reserva
                    </button>
                </div>
                <div className={(isLoading || !isExist) ? 'hidden' : 'flex flex-col'}>
                    <p className={"py-2"}>Estas a punto de cancelar tu reserva, si estas seguro, confirma que no eres un robot y pulsa el botón de Cancelar <span className={"italic text-red-500"}>(se te mandará un correo informando de que ha sido cancelada)</span></p>
                    <ReCAPTCHA
                        className={"flex flex-row justify-center"}
                        ref={recaptcha}
                        sitekey="6LehpeYlAAAAADve2BVbRquHUJN5XY6Ia4Zcl7SC"
                        onChange={onChange} />
                    <button className={"mt-4 bg-indigo-500 rounded-lg py-2 text-white"} onClick={() => onSubmit()}>Cancelar</button>
                </div>
            </div>
            <Transition
                appear={true}
                show={showModal}
                enter={"transition-opacity duration-300"}
                enterFrom={"opacity-0"}
                enterTo={"opacity-100"}
                leave={"transition-opacity duration-200"}
                leaveFrom={"opacity-100"}
                leaveTo={"opacity-0"}>
                <InformationModalComponent title={bodyModal.title} message={bodyModal.message} buttonText={bodyModal.buttonText} onChange={setShowModal} />
            </Transition>
        </div>
    )
};

export default CancelReservationConfirmationScreen;
