import React from "react";

interface IInformationModalComponent {
    title: string,
    message: string,
    buttonText: string,
    onChange: any
}

const InformationModalComponent = (props: IInformationModalComponent) => {
  return (
      <>
          <div className={"h-screen w-screen z-10 px-2 flex items-center justify-center fixed top-0 left-0 bg-black bg-opacity-50 "}>
              <div className={"w-full md:w-3/4 lg:w-1/2 z-20 flex flex-col justify-between bg-white opacity-100 rounded-lg"}>
                  <div className={"p-4"}>
                      <p className={"py-2 text-xl text-red-500"}>{props.title}</p>
                      <p className={"py-2 text-lg"}>{props.message}</p>
                      <button className={"py-2 rounded-lg text-blue-500"} onClick={() => window.location.reload()}>{props.buttonText}</button>
                  </div>
              </div>
          </div>
      </>
  );
};

export default InformationModalComponent;
